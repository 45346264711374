import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Card,
  CardTitle,
  CardText,
  CardBody,
  Row,
  Col,
} from 'design-react-kit/dist/design-react-kit';

export default function AlboArt11() {
  const [albo, setAlbo] = useState([]);

  const getAlbo = async () => {
    await getDocs(collection(db, 'albo_art_11')).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      newData.sort((a, b) =>
        a.Cognome > b.Cognome ? 1 : b.Cognome > a.Cognome ? -1 : 0,
      );
      setAlbo(newData);
    });
  };

  useEffect(() => {
    getAlbo();
  }, []);

  return (
    <div
      id="view"
      style={{
        paddingLeft: 24,
        paddingRight: 24,
        maxWidth: 1360,
        margin: '0 auto',
        fontFamily: 'Arial, Helvetica, sans-serif',
      }}
    >
      <h2>Albo - Elenco speciale art. 11</h2>
      <Row>
        {albo.map((a) => (
          <Col
            key={a.id}
            xs={12}
            md={4}
            style={{ paddingBottom: 20, paddingLeft: 10, paddingRight: 10 }}
          >
            <Card className="card card-bg no-after">
              <CardBody>
                <CardTitle tag="h5">
                  {a.Cognome} {a.Nome}
                </CardTitle>
                <CardText
                  style={{
                    fontFamily: '"Titillium Web", Geneva, Tahoma, sans-serif',
                  }}
                >
                  <span style={{ display: 'block' }}>
                    <b>Nato/a a: </b>
                    {a.LuogoNascita}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>Il: </b>
                    {moment(a.DataNascita).format('DD/MM/YYYY')}
                  </span>

                  <span style={{ display: 'block' }}>
                    <b>N. Matricola: </b>
                    {a.Matricola}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>Sezione/Settore: </b>
                    {a.SezioneSettoreIscrizione}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>Iscrizione all'albo di Lucca: </b>
                    {moment(a.DataIscrizione).format('DD/MM/YYYY')}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>Prima iscrizione all'albo: </b>
                    {moment(a.DataPrimaIscrizione).format('DD/MM/YYYY')}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>PEC: </b>
                    {a.LegalMail}
                  </span>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
