/**
 * FooterInfos component.
 * @module components/Footer/FooterInfos
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { defineMessages, useIntl } from 'react-intl';
import { Row, Col } from 'design-react-kit/dist/design-react-kit';
import { ConditionalLink } from '@plone/volto/components';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';
import { getEditableFooterColumns, getItemsByPath } from 'volto-editablefooter';
import {
  FooterNewsletterSubscribe,
  FooterSocials,
} from 'design-comuni-plone-theme/components/ItaliaTheme';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import wheelchairSVG from '../../../../public/icon-map-wheelchair.svg';

const messages = defineMessages({
  goToPage: {
    id: 'Vai alla pagina',
    defaultMessage: 'Vai alla pagina',
  },
});

const FooterInfos = () => {
  const intl = useIntl();
  const N_COLUMNS = 4;
  const location = useLocation();
  const dispatch = useDispatch();

  const footerConfiguration = useSelector(
    (state) => state.editableFooterColumns?.result,
  );

  useEffect(() => {
    dispatch(getEditableFooterColumns());
  }, [dispatch, location]);

  //filter rootpaths
  const footerColumns = getItemsByPath(
    footerConfiguration,
    location?.pathname?.length ? location.pathname : '/',
  );

  const colWidth =
    12 / (footerColumns.length < N_COLUMNS ? footerColumns.length : N_COLUMNS);

  return (
    <Row tag="div">
      {footerColumns
        .filter((c) => c.visible)
        .map((column, index) => (
          <Col
            lg={colWidth}
            md={colWidth}
            className="pb-2"
            tag="div"
            widths={['xs', 'sm', 'md', 'lg', 'xl']}
            key={index}
          >
            <h4>
              {index === 0 && (
                <div style={{ width: '30px', marginLeft: '-5px' }}>
                  <Icon
                    icon="it-map-marker-circle"
                    aria-hidden={true}
                    size="sm"
                  />
                </div>
              )}
              {index === 1 && (
                <div style={{ width: '30px', marginLeft: '-2px' }}>
                  <Icon icon="it-user" aria-hidden={true} size="sm" />
                </div>
              )}
              {index === 2 && (
                <div style={{ width: '30px', marginLeft: '-2px' }}>
                  <Icon icon="it-clock" aria-hidden={true} size="sm" />
                </div>
              )}
              {column?.title && (
                <ConditionalLink
                  condition={column.titleLink?.length > 0}
                  item={column.titleLink?.[0]}
                  to={column.titleLink?.[0]?.['@id'] ? null : ''}
                  title={
                    intl.formatMessage(messages.goToPage) + ':' + column.title
                  }
                >
                  {column.title}
                </ConditionalLink>
              )}
            </h4>
            {column.showSocial && <FooterSocials />}

            <div
              dangerouslySetInnerHTML={{
                __html: flattenHTMLToAppURL(column.text.data),
              }}
            />
            {index === 0 && (
              <>
                <img src={wheelchairSVG} alt="wheelcair icon" height={24} />
                <a
                  style={{ marginLeft: 10 }}
                  href="https://goo.gl/maps/XUnYrdF3KzwX6ZDX8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Via dell'arcivescovato n. 2
                </a>
              </>
            )}
            {index === 0 && (
              <>
                <div style={{ height: '30px' }}></div>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '-5px',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '30px' }}>
                    <Icon icon="it-download" aria-hidden={true} size="sm" />
                  </div>
                  <h4 style={{ margin: 0, padding: 0 }}>APP</h4>
                </div>
                <div style={{ height: '30px' }}></div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=it.architettilucca.app"
                    rel="noreferrer"
                    target="_blank"
                    style={{ width: 200 }}
                  >
                    <img
                      src="
https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Google_Play_Store_badge_IT.svg/1600px-Google_Play_Store_badge_IT.svg.png"
                      alt="Google Play banner"
                      width={200}
                    />
                  </a>
                  <div style={{ height: '10px' }}></div>
                  <a
                    href="https://apps.apple.com/it/app/ordine-architetti-lucca/id6468959897"
                    rel="noreferrer"
                    target="_blank"
                    style={{ width: 200 }}
                  >
                    <img
                      src="
                    https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Download_on_the_App_Store_Badge_IT_RGB_blk.svg/1200px-Download_on_the_App_Store_Badge_IT_RGB_blk.svg.png"
                      alt="App Store banner"
                      width={200}
                    />
                  </a>
                </div>
              </>
            )}
            {column.newsletterSubscribe && <FooterNewsletterSubscribe />}
          </Col>
        ))}
    </Row>
  );
};

export default FooterInfos;
