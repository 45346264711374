/**
 * Routes.
 * @module routes
 */

import { App, Search } from '@plone/volto/components';
import { defaultRoutes, multilingualRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';
import NewsLetter from './components/Newsletter/Newsletter';
import Albo from './components/Albo/Albo';
import AlboArt11 from './components/Albo/AlboArt11';

export const italiaRoutes = [
  // Add design-comuni-plone-theme routes here
  {
    path: '/**/search',
    component: Search,
  },
  {
    path: '/newsletter-email',
    component: NewsLetter,
  },
  {
    path: '/albo-dettaglio',
    component: Albo,
  },
  {
    path: '/albo-art11-dettaglio',
    component: AlboArt11,
  },
];

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      ...italiaRoutes,
      ...(config.addonRoutes || []),
      ...((config.settings?.isMultilingual && multilingualRoutes) || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
