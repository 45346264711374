/**
 * HeaderCenter component.
 * @module components/Header/HeaderCenter
 */

import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import { useSelector } from 'react-redux';
import {
  Header,
  HeaderContent,
  HeaderRightZone,
} from 'design-react-kit/dist/design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  Logo,
  SocialHeader,
  HeaderSearch,
  BrandText,
} from 'design-comuni-plone-theme/components/ItaliaTheme';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const messages = defineMessages({
  login: {
    id: 'login',
    defaultMessage: 'Accedi',
  },
});

const HeaderCenter = () => {
  const intl = useIntl();
  const subsite = useSelector((state) => state.subsite?.data);
  const logoSubsite = subsite?.subsite_logo && (
    <figure className="icon">
      <img
        src={flattenToAppURL(subsite.subsite_logo.scales?.mini?.download)}
        alt="Logo"
      />
    </figure>
  );

  return (
    <Header small={false} theme="" type="center">
      <HeaderContent>
        <div className="it-brand-wrapper">
          <UniversalLink
            href={subsite?.['@id'] ? flattenToAppURL(subsite['@id']) : '/'}
            title="homepage"
          >
            <Logo />
            <BrandText subsite={subsite} />
          </UniversalLink>
        </div>
        <HeaderRightZone>
          <SocialHeader />
          <HeaderSearch />
          <a
            href="https://www.isiformazione.it/DataWeb/entra.aspx?CCli=uOJcg0VBy64%3d"
            rel="noreferrer"
            target="_blank"
            className="btn-icon btn btn-primary log-in-button ml-2"
            title={intl.formatMessage(messages.login)}
          >
            <Icon icon="it-user" aria-hidden={true} size="sm" />
            <span className="ml-2 header-access-button-text">
              {intl.formatMessage(messages.login)}
            </span>
          </a>
        </HeaderRightZone>
      </HeaderContent>
    </Header>
  );
};

export default HeaderCenter;
