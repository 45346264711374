import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Card,
  CardTitle,
  CardText,
  CardBody,
  Row,
  Col,
} from 'design-react-kit/dist/design-react-kit';
import { Input } from 'semantic-ui-react';
import { SelectInput } from 'design-comuni-plone-theme/components';
import { Pagination } from '@plone/volto/components';

export default function Albo() {
  const [albo, setAlbo] = useState([]);
  const [alboFiltered, setAlboFiltered] = useState([]);
  const [alboWatched, setAlboWatched] = useState([]);
  const [firstNameFilter, setFirstNameFilter] = useState();
  const [lastNameFilter, setLastNameFilter] = useState();
  const [serialNumberFilter, setSerialNumberFilter] = useState();
  const [sectionOptions, setSectionOptions] = useState([]);
  const [sectionFilter, setSectionFilter] = useState({
    label: 'Tutte',
    value: null,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [dataFetched, setDataFetched] = useState(false);

  const getAlbo = async () => {
    await getDocs(collection(db, 'albo')).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      let sectionOptions = [];
      sectionOptions.push({
        label: 'Tutte',
        value: null,
      });
      new Set(newData.map((a) => a.Sezione)).forEach((s) =>
        sectionOptions.push({
          label: s,
          value: s,
        }),
      );
      setSectionOptions(sectionOptions);
      newData.sort((a, b) =>
        a.Cognome > b.Cognome ? 1 : b.Cognome > a.Cognome ? -1 : 0,
      );

      setAlbo(newData);
      setCurrentPage(0);
      setDataFetched(true);
    });
  };

  useEffect(() => {
    getAlbo();
  }, []);

  useEffect(() => {
    if (!dataFetched) {
      return;
    }
    let alboFiltered = [...albo];
    if (firstNameFilter) {
      alboFiltered = alboFiltered.filter((a) =>
        a.Nome.toUpperCase().includes(firstNameFilter.toUpperCase()),
      );
    }
    if (lastNameFilter) {
      alboFiltered = alboFiltered.filter((a) =>
        a.Cognome.toUpperCase().includes(lastNameFilter.toUpperCase()),
      );
    }
    if (serialNumberFilter) {
      alboFiltered = alboFiltered.filter((a) =>
        a.Matricola.toString().includes(serialNumberFilter.toUpperCase()),
      );
    }
    if (sectionFilter.value) {
      alboFiltered = alboFiltered.filter(
        (a) => a.Sezione === sectionFilter.value,
      );
    }

    if (currentPage >= 0) {
      let alboWatchedCopy = alboFiltered.slice(
        currentPage * pageSize,
        currentPage * pageSize + pageSize,
      );
      setAlboWatched(alboWatchedCopy);
    }
    setAlboFiltered(alboFiltered);
  }, [
    dataFetched,
    firstNameFilter,
    lastNameFilter,
    serialNumberFilter,
    sectionFilter,
    currentPage,
  ]);

  const handleQueryPaginationChange = (event, { value }) => {
    setCurrentPage(value ?? 1);
  };

  return (
    <div
      id="view"
      style={{
        paddingLeft: 24,
        paddingRight: 24,
        maxWidth: 1360,
        margin: '0 auto',
        fontFamily: 'Arial, Helvetica, sans-serif',
      }}
    >
      <h2>Albo</h2>
      <Row style={{ marginBottom: 10 }}>
        <Col xs={6} md={4}>
          <Input
            type="text"
            placeholder="Nome"
            onChange={(e) => {
              setCurrentPage(0);
              setFirstNameFilter(e.target.value);
            }}
          />
        </Col>
        <Col xs={6} md={4}>
          <Input
            type="text"
            placeholder="Cognome"
            onChange={(e) => {
              setCurrentPage(0);
              setLastNameFilter(e.target.value);
            }}
          />
        </Col>
        <Col xs={12} md={4}>
          <Input
            type="number"
            placeholder="Matricola"
            onChange={(e) => {
              setCurrentPage(0);
              setSerialNumberFilter(e.target.value);
            }}
          />
        </Col>
        <Col xs={6} md={4} mt={1}>
          <SelectInput
            id="select-section"
            value={sectionFilter}
            placeholder="Sezione"
            onChange={(e) => {
              setCurrentPage(0);
              setSectionFilter(e);
            }}
            options={sectionOptions}
          />
        </Col>
      </Row>
      <div
        style={{ marginBottom: 10 }}
        className="contents-pagination custom-pagination"
      >
        <Pagination
          current={currentPage}
          total={Math.ceil(alboFiltered.length / pageSize)}
          pageSize={pageSize}
          onChangePage={handleQueryPaginationChange}
        />
      </div>
      <Row>
        {alboWatched.map((a) => (
          <Col
            key={a.id}
            xs={12}
            md={4}
            style={{ paddingBottom: 20, paddingLeft: 10, paddingRight: 10 }}
          >
            <Card className="card card-bg no-after">
              <CardBody>
                <CardTitle tag="h5">
                  {a.Cognome} {a.Nome}
                </CardTitle>
                <CardText
                  style={{
                    fontFamily: '"Titillium Web", Geneva, Tahoma, sans-serif',
                  }}
                >
                  <span style={{ display: 'block' }}>
                    <b>Nato/a a: </b>
                    {a.LuogoNascita}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>Il: </b>
                    {moment(a.DataNascita).format('DD/MM/YYYY')}
                  </span>

                  <span style={{ display: 'block' }}>
                    <b>N. Matricola: </b>
                    {a.Matricola}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>Sezione/Settore: </b>
                    {a.SezioneSettoreIscrizione}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>Iscrizione all'albo di Lucca: </b>
                    {moment(a.DataIscrizione).format('DD/MM/YYYY')}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>Prima iscrizione all'albo: </b>
                    {moment(a.DataPrimaIscrizione).format('DD/MM/YYYY')}
                  </span>
                  <span style={{ display: 'block' }}>
                    <b>PEC: </b>
                    {a.LegalMail}
                  </span>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
