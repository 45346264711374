// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCDz6_xFHKhgNFuWO4-zB9UEHZ63-CTSrU',
  authDomain: 'architettilucca.firebaseapp.com',
  projectId: 'architettilucca',
  storageBucket: 'architettilucca.appspot.com',
  messagingSenderId: '19677629535',
  appId: '1:19677629535:web:f2a24fbbd1738c8d4edfea',
  measurementId: 'G-EQ6BG9DNB4',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
