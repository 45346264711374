import { useEffect, useState } from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import JSZip from '../../../public/jszip.js';

export default function NewsLetter() {
  const [items, setItems] = useState([]);
  const scale = 'great';

  const saveData = (blob, fileName) => {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // TODO: prendere solo le new che hanno item.Subject === 'newsletter'

  useEffect(() => {
    const fetchData = () => {
      fetch('https://www.architettilucca.it/newslettersearch', {
        method: 'POST',
        body: JSON.stringify({
          metadata_fields: '_all',
          query: [
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.selection.any',
              v: ['News Item', 'Event'],
            },
          ],
          sort_on: 'effective',
          sort_order: 'descending',
          b_start: 0,
        }),
        headers: {
          'content-type': 'application/json; charset=UTF-8',
          b_size: '0',
          limit: '10',
        },
      }).then(async (response) => {
        let body = await response.json();
        body = body.items.slice(0,10);
        for(let e of body){
            e['@id']=e['@id'].replace('https://oal.d.netfarm.it','https://www.architettilucca.it');
            e['getURL']=e['getURL'].replace('https://oal.d.netfarm.it','https://www.architettilucca.it');
        }
        setItems(body);
      });
    };
    fetchData();
  }, []);

  const composeImgUrl = (item) => {
    const getUrl = window.location;
    return (
      getUrl.protocol +
      '//' +
      getUrl.host +
      flattenToAppURL(item.image.scales[scale].download)
    );
  };

  return (
    <div
      style={{
        paddingLeft: 24,
        paddingRight: 24,
        maxWidth: 1360,
        margin: '0 auto',
        fontFamily: 'Arial, Helvetica, sans-serif',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h2>Newsletter</h2>
        <div>
          <button
            style={{
              color: 'white',
              backgroundColor: '#589e9e',
              border: 'none',
              padding: '5px 10px 5px 10px',
            }}
            onClick={() => {
              const copyText = document.getElementById('newsletter-content');
              const type = 'text/html';
              const blob = new Blob([copyText.innerHTML], { type });
              const data = [new window.ClipboardItem({ [type]: blob })];
              navigator.clipboard.write(data).then(() => {
                copyText.style.opacity = '0.4';
                setTimeout(function () {
                  copyText.style.opacity = '1';
                }, 80);
              });
            }}
          >
            COPIA NEGLI APPUNTI
          </button>
          <button
            style={{
              color: 'white',
              backgroundColor: '#589e9e',
              border: 'none',
              padding: '5px 10px 5px 10px',
              marginLeft: 15,
            }}
            onClick={() => {
              const copyText = document.getElementById('newsletter-content');
              const type = 'text/html';
              const blob = new Blob([copyText.innerHTML], { type });
              var zip = new JSZip();
              zip.file('newsletter.html', blob);
              zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveData(content, 'newsletter.zip');
              });
            }}
          >
            ESPORTA
          </button>
        </div>
      </div>

      <div style={{ height: 30 }} />
      <div id="newsletter-content">
        {items.map((item, index) => (
          <div
            key={item.id}
            style={{
              backgroundColor: '#f5f6f7',
              display: 'flex',
              flexWrap: 'wrap',
              marginRight: -6,
              marginLeft: -6,
              marginBottom: 20,
              height: 400,
            }}
          >
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                overflow: 'hidden',
              }}
            >
              {index % 2 === 0 && (
                <picture
                  style={{
                    height: 400,
                    overflow: 'hidden',
                    display: 'block',
                    width: '100%',
                  }}
                >
                  <img
                    src={composeImgUrl(item)}
                    height={400}
                    alt={item.title}
                    role="presentation"
                    aria-hidden="true"
                    title={item.title}
                  />
                </picture>
              )}
              {index % 2 !== 0 && (
                <div
                  style={{
                    padding: 24,
                    height: '100%',
                  }}
                >
                  <div style={{ marginTop: '15px', height: '100%' }}>
                    <h2
                      style={{
                        color: '#589e9e',
                        display: 'block',
                        maxHeight: '7.2em',
                        overflow: 'hidden',
                        fontSize: '1.5rem',
                        lineHeight: '1.2em',
                      }}
                    >
                      {item.title}
                    </h2>
                    <p style={{ color: '#191919' }}>{item.description}</p>
                    <a href={item.getURL} rel="noreferrer" target="_blank">
                      <button
                        style={{
                          color: 'white',
                          backgroundColor: '#589e9e',
                          border: 'none',
                          padding: '5px 10px 5px 10px',
                        }}
                      >
                        DETTAGLIO
                      </button>
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                overflow: 'hidden',
              }}
            >
              {index % 2 !== 0 && (
                <picture
                  style={{
                    height: 400,
                    overflow: 'hidden',
                    display: 'block',
                    width: '100%',
                  }}
                >
                  <img
                    src={composeImgUrl(item)}
                    height={400}
                    alt={item.title}
                    role="presentation"
                    aria-hidden="true"
                    title={item.title}
                  />
                </picture>
              )}
              {index % 2 === 0 && (
                <div
                  style={{
                    padding: 24,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <div style={{ marginTop: '15px', height: '100%' }}>
                    <h2
                      style={{
                        color: '#589e9e',
                        display: 'block',
                        maxHeight: '7.2em',
                        overflow: 'hidden',
                        fontSize: '1.5rem',
                        lineHeight: '1.2em',
                      }}
                    >
                      {item.title}
                    </h2>
                    <p style={{ color: '#191919' }}>{item.description}</p>
                    <a href={item.getURL} rel="noreferrer" target="_blank">
                      <button
                        style={{
                          color: 'white',
                          backgroundColor: '#589e9e',
                          border: 'none',
                          padding: '5px 10px 5px 10px',
                        }}
                      >
                        DETTAGLIO
                      </button>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
